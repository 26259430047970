<template>
  <div v-tooltip="description" :class="statusClass">
    {{ statusText }}
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: {
    status: {
      type: String,
      required: true,
      validator: (value) =>
        [
          "aberto",
          "fechado",
          "pendente",
          "invalido",
          "incompleto",
          "valido",
          "pago",
          "já recebeu pagamento",
          "não receberá pagamento",
          "receberá pagamento",
        ].includes(value),
    },
    description: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    statusBadgeOptions: {
      aberto: {
        text: "Aberto",
        color: "light-info",
      },
      pendente: {
        text: "Pendente",
        color: "info",
      },
      invalido: {
        text: "Inválido",
        color: "light-warning",
      },
      incompleto: {
        text: "Incompleto",
        color: "danger",
      },
      valido: {
        text: "Válido",
        color: "success",
      },
      pago: {
        text: "Pago",
        color: "success",
      },
      fechado: {
        text: "Fechado",
        color: "danger",
      },
      "receberá pagamento": {
        text: "Receberá Pagamento",
        color: "info",
      },
      "já recebeu pagamento": {
        text: "Já Recebeu Pagamento",
        color: "success",
      },
      "não receberá pagamento": {
        text: "Não Receberá Pagamento",
        color: "danger",
      },
    },
  }),
  computed: {
    statusClass() {
      return `badge badge-${this.statusBadgeOptions[this.status]?.color}`;
    },
    statusText() {
      return this.statusBadgeOptions[this.status]?.text ?? "";
    },
  },
};
</script>

<style scoped></style>
