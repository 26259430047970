<template>
  <table class="table" :hidden="isLoading">
    <tbody>
      <tr
        v-for="survey in surveys"
        :key="survey.id"
        @click="setSelectedSurvey(survey)"
      >
        <td>
          <div
            style="
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
            "
          >
            <span>{{ survey.survey_name }}</span>
            <status-badge :status="getSurveyStatus(survey.is_open)" />
          </div>
          <div style="display: flex; justify-content: space-between">
            <b>{{ formatNumberAsCurrency(survey.survey_value) }}</b>
            <status-badge
              :status="survey.final_status"
              :description="survey.status_description"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusBadge from "@/components/StatusBadge/index.vue";

export default {
  name: "index",
  components: { StatusBadge },
  props: {
    isLoading: { type: Boolean, default: false },
    surveys: { type: Array, default: () => [] },
    formatNumberAsCurrency: { type: Function, default: () => {} },
    getSurveyStatus: { type: Function, default: () => {} },
  },
  methods: {
    setSelectedSurvey(survey) {
      this.$emit("set-survey", survey);
    },
  },
};
</script>

<style scoped>
.table {
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;
  white-space: nowrap;
}

.table td,
.table th {
  text-align: left;
}

.table th {
  padding: 0 20px;
  font-size: 0.9rem;
  line-height: 1;
}

.table td {
  padding: 20px;
  background-color: white;
}

.table tr td:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table tr td:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
