import { api } from "../config";

export default {
  getUserSurveys: () =>
    api({
      method: "GET",
      url: "/respondents/surveys?limit=20&page=0",
    }),
  checkSurveyQuota: (userSurveyId) =>
    api({
      method: "GET",
      url: "/users-surveys/check-survey-quota",
      params: {
        user_survey_id: userSurveyId,
      },
    }),
  setSurveyOpened: (userSurveyId) =>
    api({
      method: "PATCH",
      url: `/users-surveys/${userSurveyId}`,
    }),
};
