import axios from "axios";
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accepted: "appication/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiCep = axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

const apiIBGE = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
});
const apiBOT = axios.create({
  baseURL: "https://urchin-app-2xkpw.ondigitalocean.app",
  withCredentials: true,
  headers: {
    Accepted: "appication/json",
    "Content-Type": "application/json",
  },
});
apiBOT.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export { api, apiCep, apiIBGE, apiBOT };
