<template>
  <div class="chat">
    <input id="click" v-model="checkopen" type="checkbox" />
    <label class="float" for="click">
      <i class="fas fa-comment-dots open"></i>
      <i class="fas fa-times close"></i>
    </label>
    <div ref="toogle" class="container-wrapper">
      <div class="head-text">Chat - Inside</div>

      <div id="chat-box" ref="messageRef" class="chat-box">
        <div class="message-bot">
          Oi, tudo bem? Eu sou o assistente virtual da Inside. Selecione um dos
          assuntos abaixo ou digite sua pergunta para continuar:
        </div>
        <div class="message-bot">
          <div class="options">
            <div class="option-bot" @click="setMessage('cadastro')">
              Cadastro
            </div>
            <div class="option-bot" @click="setMessage('pesquisas')">
              Pesquisas
            </div>
            <div class="option-bot" @click="setMessage('pagamentos')">
              Pagamentos
            </div>
          </div>
        </div>
        <div v-if="messages.length > 0">
          <div v-for="(message, index) in messages" :key="index">
            <div
              :class="classNameTypeMessage(message.type)"
              v-text="message.message"
            ></div>
            <div>
              <div v-if="message.action" class="message-bot">
                <div class="actions-bot">
                  <div class="action-bot" @click="setMessage('sim')">Sim</div>
                  <div class="action-bot" @click="setMessage('não')">Não</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="left-footer">
          <div class="left-footer-container">
            <div class="input-group">
              <div class="input-container">
                <div class="inp">
                  <input
                    v-model="messageUser"
                    placeholder="Mensagem"
                    type="text"
                    @keyup.enter="sendMessageBot"
                  />
                </div>
              </div>
            </div>
            <div class="btn-container">
              <button @click="sendMessageBot()">
                <span class="material-symbols-outlined"> send </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceChatBot from "@/api/services/chatbot.js";

export default {
  data() {
    return {
      messages: [],
      messageUser: null,
      isActiveEl: false,
      checkopen: false,
    };
  },
  watch: {
    messages: function () {
      this.$nextTick(function () {
        var container = this.$refs.messageRef;
        container.scrollTop = container.scrollHeight + 120;
      });
    },
  },

  methods: {
    setMessage(message) {
      this.messageUser = message;
      this.sendMessageBot();
    },
    classNameTypeMessage(type) {
      return type === "bot" ? "message-bot" : "message-user";
    },
    async sendMessageBot() {
      let messagesend;
      const defaultmessages = {
        cadastro: "editar cadastro",
        pesquisas: "receber mais pesquisas",
        pagamentos: "meu pagamento não caiu",
      };
      this.messages.push({
        message: this.messageUser,
        type: "user",
      });
      if (
        this.messageUser === "cadastro" ||
        this.messageUser === "pesquisa" ||
        this.messageUser === "pagamentos"
      ) {
        messagesend = defaultmessages[this.messageUser];
      } else {
        messagesend = this.messageUser;
      }
      try {
        const response = await ServiceChatBot.sendMessage(messagesend);
        response.data.message.forEach((element) => {
          if (element === "A resposta foi de acordo com sua pergunta?") {
            this.messages.push({
              message: element,
              type: "bot",
              action: true,
            });
          } else {
            this.messages.push({
              message: element,
              type: "bot",
              action: false,
            });
          }
        });
        this.messageUser = null;
      } catch (error) {
        this.messages.push({
          message:
            "Parece que estamos com problemas em um de nossos servidores. Tente novamente mais tarde.",
          type: "bot",
        });
        console.log(error);
      }
    },
  },
};
</script>

<style>
.message-bot {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  background: #f2f4f5;
  border-radius: 0px 24px 24px;
  margin: 15px 0px;
}
.message-user {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  margin: 15px 0px;

  background: #d0b6fb;
  border-radius: 24px 24px 0px 24px;
}
#click {
  display: none;
}
.chat label {
  height: 55px;
  width: 55px;
  background: -webkit-linear-gradient(left, #7635dc, #cc42fa);
  text-align: center;
  border-radius: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.chat label i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
}
.chat label i.close {
  opacity: 0;
  pointer-events: none;
}
.chat #click:checked ~ label i.close {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) rotate(180deg);
}
.chat #click:checked ~ label i.open {
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) rotate(180deg);
}
.chat .container-wrapper {
  position: fixed;
  right: 30px;
  bottom: 0px;
  max-width: 350px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.chat #click:checked ~ .container-wrapper {
  opacity: 1;
  bottom: 115px;
  pointer-events: auto;
}
.chat .container-wrapper .head-text {
  line-height: 60px;
  color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 0 20px;
  font-weight: 500;
  font-size: 20px;
  background: -webkit-linear-gradient(left, #7635dc, #cc42fa);
}
.chat .container-wrapper .chat-box {
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;
  height: 400px;
  font-size: 0.875em;
}
.chat .chat-box .desc-text {
  color: #515365;
  text-align: center;
  line-height: 25px;
  font-size: 17px;
  font-weight: 500;
}

.chat textarea {
  border: 1px solid lightgrey;
  outline: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.chat .input-group {
  padding: 8px 15px;
  border-radius: 10px;
  background-color: #f1f3f6;
  margin-top: 8px;
}
.chat ::placeholder {
  font-size: 14px;
  color: #a5adb9;
}
.chat .input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat textarea {
  border: 0;
  outline: 0;
  background-color: transparent;
}
.chat .btn-container button {
  margin-top: 22px;
  border: 0;
  height: 55px;
  width: 55px;
  background: -webkit-linear-gradient(left, #7635dc, #cc42fa);
  text-align: center;
  border-radius: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.btn-container span {
  color: white;
}
.chat .left-footer-container {
  display: flex;
  align-items: center;
  gap: 10px 5px;
  padding: 10px;
  width: 90%;
}
.chat img {
  cursor: pointer;
}
.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.option-bot {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px;
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 12px;
  margin: 5px 0px;
  border: 0;
  font-size: 0.875em;
}
.option-bot:hover {
  background: -webkit-linear-gradient(left, #7635dc, #cc42fa);
  border: 0;
  color: white;
}
.action-bot {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px;
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 12px;
  margin: 5px;
  border: 0;
  font-size: 0.875em;
}
.action-bot:hover {
  background: -webkit-linear-gradient(left, #7635dc, #cc42fa);
  border: 0;
  color: white;
}
.actions-bot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
