import { api } from "../config";

export default {
  getSearchs: ({ response_status_id, page, itemsPerPage }) =>
    api({
      method: "GET",
      url: "/user-dashboard/responses",
      params: {
        response_status_id,
        page,
        itemsPerPage,
      },
    }),
  getReceipts: () =>
    api({
      method: "GET",
      url: "/user-dashboard/receipts",
    }),
  getFamilyIncomes: () =>
    api({
      method: "GET",
      url: "/family_income",
    }),
  getGenders: () =>
    api({
      method: "GET",
      url: "/respondents/genders",
    }),
};
