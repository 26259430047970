import { apiBOT } from "../config";

export default {
  sendMessage: (message) =>
    apiBOT({
      method: "POST",
      url: "/chatbot-chatbot-api2",
      data: {
        message,
      },
    }),

};
