<template>
  <div>
    <div class="menu">
      <div class="header">
        <div class="nav-bar">
          <div class="logo">
            <img alt="Logo" src="@/assets/img/insideBrasil1.png" />
          </div>
          <div>
            <ul class="main-nav">
              <li>
                <router-link :to="{ name: 'main' }">Inicio</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'profile' }">Minha conta</router-link>
              </li>
              <li>
                <a :href="faqUrl" target="_blank">Dúvidas</a>
              </li>
              <!--              <li>-->
              <!--                <router-link :to="{ name: 'surveys' }"-->
              <!--                  >Minhas Pesquisas</router-link-->
              <!--                >-->
              <!--              </li>-->
              <li><router-link :to="{ name: 'login' }">Sair</router-link></li>
            </ul>
          </div>
          <div id="menu-responsive" @click="openNav">
            <span class="material-icons">menu</span>
          </div>
        </div>
      </div>
    </div>
    <div id="myNav" class="overlay">
      <a class="closebtn" href="javascript:void(0)" @click="closeNav()">
        <span class="material-icons">close</span>
      </a>
      <div class="overlay-content">
        <router-link :to="{ name: 'main' }">Inicio</router-link>
        <a @click="setAccount">Minha conta</a>
        <a :href="faqUrl" target="_blank">Dúvidas</a>
        <!--        <a @click="setSurveys">Minhas Pesquisas</a>-->
        <a @click="logout">Sair</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    faqUrl: "https://home.insidebrasil.net/faq",
  }),
  methods: {
    logout() {
      localStorage.clear("token");
      this.$router.push({ name: "login" });
    },
    setAccount() {
      this.$router.push({ name: "profile" });
    },
    setSurveys() {
      this.$router.push({ name: "surveys" });
    },
    openNav() {
      document.getElementById("myNav").style.width = "100%";
    },

    closeNav() {
      document.getElementById("myNav").style.width = "0%";
    },
  },
};
</script>

<style>
.menu {
  border-bottom: 2px solid #f4f4f4;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  background: white;
}
.menu .header {
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  background: white;
}

.nav-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 17px;
}
.main-nav a {
  color: #818181;
  padding: 15px 25px;
}
.main-nav a:hover {
  background-color: rgb(189, 189, 189);
  border-radius: 0.4rem;
  padding: 15px 25px;
  color: #070606;
}

.main-nav ul {
  list-style: none;
}

.menu .header .logo {
  width: 195px;
}
.menu .header .logo img {
  width: 80%;
  cursor: pointer;
}

.menu .header li {
  padding: 0 20px;
  list-style: none;
  font-size: 0.8rem;
  cursor: pointer;
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* RESPONSIVE ============================= */
@media (max-width: 1068px) {
  .main-nav {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 1068px) {
  #menu-responsive {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 700px) {
  .menu .header {
    width: 100%;
    padding: 0 0;
  }
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
  #menu-responsive img {
    width: 90%;
  }
}
</style>
