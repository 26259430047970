<template>
  <div>
    <div class="header-wrapper main-home" :hidden="surveys.length === 0">
      <div class="header">
        <span>Minhas últimas 20 Pesquisas</span>
      </div>
    </div>
    <div class="main-surveys">
      <custom-loading :hidden="!isLoading" />

      <SurveyListDesktop
        @set-survey="setSelectedSurvey"
        v-if="!isMobile"
        :get-survey-status="getSurveyStatus"
        :is-loading="isLoading"
        :surveys="surveys"
        :format-number-as-currency="formatNumberAsCurrency"
      />
      <SurveyListMobile
        v-if="isMobile"
        @set-survey="setSelectedSurvey"
        :get-survey-status="getSurveyStatus"
        :is-loading="isLoading"
        :surveys="surveys"
        :format-number-as-currency="formatNumberAsCurrency"
      />
    </div>
    <iframe-viewer
      @close-survey="setSelectedSurvey(null)"
      v-if="selectedSurvey"
      :url="selectedSurvey.survey_link"
    />
  </div>
</template>

<script>
import userSurveys from "../../api/services/userSurveys";
import IframeViewer from "@/components/IframeViewer/index.vue";
import SurveyListDesktop from "@/components/SurveyListDesktop/index.vue";
import SurveyListMobile from "@/components/SurveyListMobile/index.vue";
import CustomLoading from "@/components/Loading/Loading.vue";

export default {
  name: "surveys",
  components: {
    SurveyListDesktop,
    SurveyListMobile,
    IframeViewer,
    "custom-loading": CustomLoading,
  },
  data: () => ({
    surveys: [],
    selectedSurvey: null,
    isLoading: true,
    isMobile: false,
  }),
  mounted() {
    this.getMySurveys();

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize);
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 650;
    },
    getSurveyStatus(status = false) {
      return status ? "aberto" : "fechado";
    },
    formatNumberAsCurrency(value) {
      const formatter = Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    async setSelectedSurvey(survey) {
      if (!survey.is_open)
        return this.$toast.error("Este questionário está fechado");

      try {
        this.isLoading = true;

        if (survey) {
          await userSurveys.setSurveyOpened(survey.id);
          const response = await userSurveys.checkSurveyQuota(survey.id);

          this.selectedSurvey = { ...survey, survey_link: response.data.url };
        } else {
          this.selectedSurvey = null;
        }
      } catch (err) {
        this.$toast.error(err?.response?.data?.message ?? "Erro interno");
      } finally {
        await this.getMySurveys();

        this.isLoading = false;
      }
    },
    async getMySurveys() {
      try {
        this.isLoading = true;

        const response = await userSurveys.getUserSurveys();

        this.surveys = response.data.surveys;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.main-surveys {
  margin: 0 auto;
  max-width: 100vw;
  overflow: auto;
}

.header-wrapper {
  padding: 10px 20px 0;
}
</style>
