import { api } from "../config";

export default {
  verifyToken: async () =>
    api({
      method: "GET",
      url: "/user/profile",
    }),
  signin: (user) =>
    api({
      method: "POST",
      url: "/session",
      data: user,
    }),
  signUp: (user) =>
    api({
      method: "POST",
      url: "/respondents",
      data: user,
    }),
  isValidateUser: (user) => {
    return api({
      method: "POST",
      url: "/user/validator",
      data: user,
    });
  },
  completeUserRegistration: (user) =>
    api({
      method: "POST",
      url: "/respondents/register",
      data: user,
    }),
  confirmAuth: (token) =>
    api({
      method: "POST",
      url: `/respondents/activate?token=${token}`,
    }),
  forgotPass: (user) =>
    api({
      method: "POST",
      url: `/forgot_password`,
      data: user,
    }),
  sendPass: (pass, token) =>
    api({
      method: "POST",
      url: `/reset_password/${token}`,
      data: pass,
    }),
  resendMail: (email) =>
    api({
      method: "POST",
      url: `/user/resend_mail`,
      data: email,
    }),
};
