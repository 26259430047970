import { api } from "../config";

export default {
  getAddress: () =>
    api({
      method: "GET",
      url: "/address",
    }),
  sendToAddress: (address, method) =>
    api({
      method,
      url: "/address",
      data: address,
    }),
  getUserData: () =>
    api({
      method: "GET",
      url: "/user_data",
    }),
  updateUserData: (userData) =>
    api({
      method: "PUT",
      url: "/user_data",
      data: userData,
    }),
  getUserIndications: () =>
    api({
      method: "GET",
      url: "/indications",
    }),
  updateUserStatus: (userId, userStatus) =>
    api({
      method: "PATCH",
      url: `/user/status/${userId}`,
      data: {
        user_status_id: userStatus,
      },
    }),
  getCurrentUser: () =>
    api({
      method: "GET",
      url: "/user/current",
    }),
  getAvailableGenders: () =>
    api({
      method: "GET",
      url: "/respondents/genders",
    }),
  saveNewEmail: ({ email, newEmail, password }) =>
    api({
      method: "PATCH",
      url: "/user/update_email",
      data: { email, newEmail, password },
    }),
};
