<template>
  <table class="table" :hidden="isLoading">
    <thead>
      <tr>
        <th>Título da Pesquisa</th>
        <th>Valor</th>
        <th>Status da Pesquisa</th>
        <th>Pagamento</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="survey in surveys"
        :key="survey.id"
        @click="setSelectedSurvey(survey)"
      >
        <td>{{ survey.survey_name }}</td>
        <td>{{ formatNumberAsCurrency(survey.survey_value) }}</td>
        <td>
          <status-badge :status="getSurveyStatus(survey.is_open)" />
        </td>
        <td>
          <status-badge
            :status="survey.final_status"
            :description="survey.status_description"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusBadge from "@/components/StatusBadge/index.vue";

export default {
  name: "index",
  components: { StatusBadge },
  props: {
    isLoading: { type: Boolean, default: false },
    surveys: { type: Array, default: () => [] },
    formatNumberAsCurrency: { type: Function, default: () => {} },
    getSurveyStatus: { type: Function, default: () => {} },
  },
  methods: {
    setSelectedSurvey(survey) {
      this.$emit("set-survey", survey);
    },
  },
};
</script>

<style scoped>
.table {
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;
  white-space: nowrap;
}

.table td,
.table th {
  text-align: left;
}

.table th {
  padding: 0 20px;
  font-size: 0.9rem;
  line-height: 1;
}

.table td {
  padding: 20px;
  background-color: white;
}

.table tr td:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table tr td:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
