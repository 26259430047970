<template>
  <div class="card-indication-info">
    <div class="card-indication-info-content">
      <div class="text-description">
        <span>
          Na Inside Brasil você é remunerado por cada amigo(a) indicado(a). Não
          existe limite de indicações. Quanto mais amigos você indicar, mais
          você ganha.
        </span>
        <span>
          Para receber a recompensa pela sua indicação é necessário que:
        </span>
        <ul>
          <li>
            <span>
              A pessoa que você indicou esteja cadastrado no site:
              www.insidebrasil.net
            </span>
          </li>
          <li>
            <span>
              Que ele(a) coloque o seu código de indicação no cadastro dele(a)
            </span>
          </li>
          <li>
            <span>
              E que ele ou ela tenha respondido a pelo menos uma pesquisa
              válida.
            </span>
          </li>
        </ul>
        <span> O valor é depositado na sua conta do PayPal. </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicationsInfo",
};
</script>

<style lang="scss" scoped>
.card-indication-info {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  background: #f8f9fa;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  margin: 1em 0em;
}
.card-indication-info-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.875em 0rem;
}
</style>
