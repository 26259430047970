import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./styles/global.css";
import VTooltip from "v-tooltip";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast, {
  position: "top-right",
});
Vue.use(VTooltip);

import { vfmPlugin } from "vue-final-modal";
Vue.use(vfmPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
