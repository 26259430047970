<template>
  <div class="iframe-container">
    <div class="iframe-close" @click="$emit('close-survey')">X</div>
    <iframe :src="url" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: "IframeViewer",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  mounted() {
    document.body.style.overflow = "hidden"; // Impede a rolagem da página quando o iframe está aberto
  },
  beforeDestroy() {
    document.body.style.overflow = ""; // Restaura a rolagem da página quando o componente é destruído
  },
};
</script>

<style scoped>
.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
}

.iframe-close {
  position: absolute;
  right: 24px;
  font-size: 30px;
  cursor: pointer;
  color: red;
}

iframe {
  width: 100%;
  height: 100%;
}
</style>
