<template>
  <div class="home">
    <nav-bar> </nav-bar>
    <div class="main-home">
      <div class="header">
        <span>Resumo</span>
      </div>
      <div class="resume d-flex flex-start">
        <div class="card col-md-4 col-sd-12 col-xs-12">
          <div class="card-content">
            <div>
              <div>
                <span class="total-value" v-text="totalRecepit" />
              </div>
              <span class="legend">você já recebeu na sua conta do paypal</span>
            </div>
          </div>
        </div>
        <div class="card col-md-4 col-sd-12 col-xs-12">
          <div class="card-content">
            <div>
              <div>
                <span class="total-value" v-text="user.recommendation_code" />
              </div>
              <span class="legend">Código de indicação </span>
            </div>
            <div class="content_copy" @click="copyRecommendationURL">
              <span style="font-size: 30px" class="material-symbols-outlined">
                content_copy
              </span>
            </div>
          </div>
        </div>
      </div>
      <surveys />
      <div class="mt-10 container">
        <div>
          <span>Como funciona o sistema de indicações:</span>
        </div>
        <IndicationsInfo />
      </div>
    </div>
  </div>
</template>

<script>
import searchs from "@/api/services/searchs.js";
import user from "@/api/services/user.js";
import auth from "@/api/services/auth.js";
import { convertMoney } from "@/utils/conversions";
import NavBar from "../components/NavBar/NavBar.vue";
import IndicationsInfo from "@/components/IndicationsInfo";
import Surveys from "@/views/account/Surveys.vue";

export default {
  name: "Home",
  components: {
    Surveys,
    IndicationsInfo,
    NavBar,
  },
  beforeMount() {
    this.getAddress();
    this.getProfile();
  },
  mounted() {
    this.getReceipts();
  },
  data() {
    return {
      items: [],
      perPage: 5000,
      page: 1,
      total: 0,
      status: 0,
      loading: false,
      qtdPages: 0,
      totalRecepit: 0,
      totalResponses: 0,
      toastInstance: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    generateRecommendationURL(recommendation_code = "") {
      return (
        process.env.VUE_APP_APP_URL + "/register/?code=" + recommendation_code
      );
    },
    copyRecommendationURL() {
      if (this.toastInstance) this.toastInstance.dismiss();

      const recommendationURL = this.generateRecommendationURL(
        this.user.recommendation_code
      );

      this.toastInstance = this.$toast.open({
        message: "Copiado para a área de transferência",
        type: "default",
        position: "bottom",
      });

      navigator.clipboard.writeText(recommendationURL);
    },
    async getProfile() {
      try {
        const response = await auth.verifyToken();

        this.$store.commit("setUser", response.data);
      } catch (error) {
        console.log("error", error?.response?.data);
      }
    },
    handlePagination(params) {
      this.page = Number(params.page);
      this.perPage = Number(params.perpage);
    },
    async getReceipts() {
      const response = await searchs.getReceipts();
      this.totalRecepit = convertMoney(response.data.totalReceipted);
    },
    async getAddress() {
      try {
        const response = await user.getAddress();
        const address = response.data.address;
        const isRequiredAddress = Object.values(address || {}).length === 0;
        if (isRequiredAddress) {
          this.$router.push("/address-profile");
        }
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.main-home {
  padding: 2rem 1em;
  max-width: 1200px;
  margin: 0 auto;
}
.main-home .container {
  margin-top: 2rem;
}
.total-value {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}
.legend {
  font-size: 0.8rem;
  font-family: "Inter", sans-serif;
}
span {
  font-weight: normal;
  color: #818181;
  padding: 0;
  margin: 0;
}
.card-content {
  padding: 15px 30px;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.card {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  background: #f8f9fa;
}

.card:first-child {
  margin-right: 16px;
}

.content_copy {
  display: flex;
  flex-direction: column;
  justify-content: center;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.content_copy:hover {
  cursor: pointer;
}

@media (max-width: 480px) {
  .resume {
    flex-direction: column !important;
  }

  .card:first-child {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
</style>
